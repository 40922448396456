import { Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import React from "react";
import styled from "styled-components";

const Stars = styled(Div)`
  font-size: ${(p) => p.theme.fontSize.lg};
`;

const FullStar = styled.span`
  color: ${(p) => p.starColor || p.theme.colors.themeYellow};
`;

const HalfStar = styled.span`
  display: inline-block;
  color: transparent;
  position: relative;
  ${(p) =>
    `background-image: linear-gradient(90deg,${
      p.starColor || p.theme.colors.themeYellow
    }  50%, gray 50%)`};
  -webkit-background-clip: text;
  background-clip: text;
`;
const StarRating = ({ rating, starColor }) => {
  const fullStars = Math.floor(rating);
  const halfStars = rating % 1;

  const stars = [];
  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <FullStar starColor={starColor} key={i}>
        &#9733;
      </FullStar>
    );
  }
  if (halfStars > 0) {
    stars.push(
      <HalfStar starColor={starColor} key="half">
        &#9733;
      </HalfStar>
    );
  }

  return <Stars className="star-rating">{stars}</Stars>;
};

export default StarRating;
