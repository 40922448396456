import React from "react";
import CourseSectionForCmsView from "../Commons/UtilityComponents/CourseSectionForCms.view";
import styles from "./HaloSctn.module.css";
import StarRating from "@/Components/UI/StarRating";
import CTAsController from "./CTAs.controller";
import BreadCrumbsView from "./BreadCrumbs.view";
import Image from "next/image";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";
import ShowUnsubscribedComponent from "../Commons/UtilityComponents/ShowUnsubscribedComponent.view";

const HaloSctnView = ({
  title,
  section_id,
  description,
  reviews,
  rating,
  listData2Meta,
  boxText,
  crossedString,
  cta1,
  cta2,
  metaString,
  image,
  breadcrumbLinks,
  ctaBtnsMiddleText,
  ctaBtnsMiddleLink,
  ctaBtnsHeading,
  ctaBtnsSubheading,
  useDefaultCta = false,
  defaultCtaVariant = "transparent",
  disableBreadcrumbs = false,
}) => {
  if (!useDefaultCta) {
    defaultCtaVariant = "transparent";
  }

  return (
    <ShowUnsubscribedComponent>
      <div
        className={`section-page-title page-title style-8 ${styles["halo-wrapper"]}`}
        id={section_id}
      >
        {image?.url ? (
          <div className={styles["img-wrapper"]}>
            <Image
              alt="Halo Section Bg"
              className={styles["bg-image"]}
              fill={true}
              src={image?.url}
              quality={70}
              priority
            />
          </div>
        ) : (
          <></>
        )}
        <div className={`tf-container ${styles["halo-container"]}`}>
          <div className="row">
            <div className="col-lg-8">
              <div className="content">
                {!disableBreadcrumbs && (
                  <BreadCrumbsView
                    links={breadcrumbLinks}
                    section_id={section_id}
                  />
                )}
                {title && (
                  <h2
                    className="font-cardo fw-7"
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></h2>
                )}
                {description && <p className="except">{description}</p>}
                {(rating || reviews || listData2Meta?.length > 0) && (
                  <div
                    className="entry-meta"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {listData2Meta?.length > 0 && (
                      <ul className="entry-meta">
                        <li>
                          {listData2Meta?.map((item, index) => (
                            <React.Fragment
                              key={`HaloSctn-listData2Meta-${index}`}
                            >
                              <span>
                                <DynamicIcon icon={item?.icon} />
                                {item.text}
                              </span>
                              {index !== listData2Meta.length - 1 && (
                                <span className={styles.bullet}>•</span>
                              )}
                            </React.Fragment>
                          ))}
                        </li>
                      </ul>
                    )}
                    {(rating || reviews) && (
                      <ul className="entry-meta">
                        <li>
                          <div className="ratings">
                            <div className="number">{rating}</div>
                            <StarRating rating={rating} starColor="white" />
                            <p className="total fs-14">{reviews} </p>
                          </div>
                        </li>
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="right-sale">
                {(metaString || crossedString || boxText) && (
                  <div className="course-price">
                    <div className="price">
                      {metaString ? (
                        <h3 className="fw-5">{metaString}</h3>
                      ) : (
                        <></>
                      )}
                      {crossedString ? (
                        <h6 className={styles["box-text"]}>{crossedString}</h6>
                      ) : (
                        <></>
                      )}
                    </div>
                    {boxText ? <p className="sale-off">{boxText}</p> : <></>}
                  </div>
                )}
                <CTAsController
                  ctaBtnsMiddleText={ctaBtnsMiddleText}
                  ctaBtnsMiddleLink={ctaBtnsMiddleLink}
                  cta1={cta1}
                  cta2={cta2}
                  section_id={section_id}
                  useDefaultCta={useDefaultCta}
                  ctaBtnsHeading={ctaBtnsHeading}
                  ctaBtnsSubheading={ctaBtnsSubheading}
                  defaultCtaVariant={defaultCtaVariant}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ShowUnsubscribedComponent>
  );
};

export default HaloSctnView;

export const CMSHaloSctnView = CourseSectionForCmsView(HaloSctnView);
