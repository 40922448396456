import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import styles from "./HaloSctn.module.css";
import Link from "next/link";
import React from "react";
import { convertSlugToString } from "@/outscal-commons-frontend/utils/stringOperations";
import { useRouter } from "next/router";

const BreadCrumbsView = ({ links, section_id }) => {
  const router = useRouter();

  const generateBreadcrumbs = (asPath) => {
    const segments = asPath.split("/").filter(Boolean);
    const breadcrumbs = [];

    if (
      router.pathname !== "/courses" &&
      router.pathname !== "/game-development-courses"
    ) {
      breadcrumbs.push({
        text: "Home",
        icon: "BsArrowUpRight",
        link: "/",
      });
    }

    let path = "";
    for (let i = 0; i < segments.length - 1; i++) {
      path += `/${segments[i]}`;
      breadcrumbs.push({
        text: convertSlugToString(segments[i]),
        link: path,
      });
    }

    return breadcrumbs;
  };

  const breadcrumbLinks =
    links?.length > 0 ? links : generateBreadcrumbs(router.asPath);

  return breadcrumbLinks?.length ? (
    <ul
      className={`breadcrumbs breadcrumbs flex items-center justify-start gap-10 mb-60`}
    >
      {breadcrumbLinks?.map((item, index) => (
        <li key={item.id} className="flex">
          {item?.link && (
            <Link
              className={`${styles["white-color"]}`}
              href={item?.link}
              data-analytics={[UIElements.LINK, `${section_id}-breadcrumb`]}
            >
              {item.text}
            </Link>
          )}
          {index < breadcrumbLinks?.length - 1 && (
            <div className={styles["breadcrumb-icon"]}>
              <DynamicIcon icon="FaChevronRight" />
            </div>
          )}
        </li>
      ))}
    </ul>
  ) : null;
};

export default BreadCrumbsView;
